<template>
    <div class="top-header">
        <div class="container-fluid">
            <div class="logo">
                <NuxtLink :to="'/'">
                    <span>GO</span> - vue
                </NuxtLink>
            </div>
            <div id="menu" class="menu">
                <div @click="mobileMenu = !mobileMenu" class="menu__btn">
                    <div class="menu__row"></div>
                    <div class="menu__row"></div>
                    <div class="menu__row"></div>
                </div>
                <ul :class="{active : mobileMenu}">
                    <svg
                            @click="mobileMenu = false"
                            class="close-menu"
                            width="28"
                            height="28"
                            viewBox="0 0 14 14"
                            xmlns="http://www.w3.org/2000/svg"
                            data-svg="close-icon"
                    >
                        <line

                                stroke-width="1.1"
                                x1="1"
                                y1="1"
                                x2="13"
                                y2="13"
                        />
                        <line

                                stroke-width="1.1"
                                x1="13"
                                y1="1"
                                x2="1"
                                y2="13"
                        />
                    </svg>
                    <li @click="scrollToSection('about'); mobileMenu = false">
                        <NuxtLink :to="{ path: '/',hash:'#about'}">Кто Я?</NuxtLink>
                    </li>
                    <li @click="scrollToSection('portfolio'); mobileMenu = false">
                        <NuxtLink :to="{ path: '/',hash:'#portfolio'}">Портфолио</NuxtLink>
                    </li>
                    <li @click="scrollToSection('services'); mobileMenu = false">
                        <NuxtLink :to="{ path: '/',hash:'#services'}" class="active">Stack</NuxtLink>
                    </li>
                    <li @click="scrollToSection('contact'); mobileMenu = false">
                        <NuxtLink :to="{ path: '/',hash:'#contact'}">Связаться</NuxtLink>
                    </li>
                </ul>
            </div>
        </div>
    </div>
  <!-- end portfolio -->
</template>

<script>

export default {
    name: "HeaderTop",
  data () {
    return {
        mobileMenu: false

    }
  },
  mounted () {
    console.log('start mountend header2')
  },
  methods: {
    scrollToSection(id) {
      const section = document.getElementById(id);
      if (section) {
        section.scrollIntoView({ behavior: 'smooth' });
      }
    }
  }
}
</script>
